/**
 *  Property of OSF GLOBAL SERVICES INC. , an OSF Digital company. OSF remains the sole owner of all right, title and interest in the software. Do not copy, sell, reverse engineer or otherwise attempt to derive or obtain information about the functioning, manufacture or operation therein.
 */

"use strict";

var unescape = require("./utils/unescape");

class CBTController {
    /**
     * function to push data to the dataLayer, and add hash
     * @param {Object} data - data to push
     */
    static hashPush(data) {
        var dataReassigned = data;
        if (dataReassigned) {
            if (dataReassigned.options !== "Quantity" || dataReassigned.options !== "Options") {
                delete dataReassigned.options;
            }
            if (dataReassigned.eventTypes) {
                delete dataReassigned.eventTypes;
            }
            if (dataReassigned.selector) {
                delete dataReassigned.selector;
            }
            // eslint-disable-next-line no-restricted-globals
            if ((location.hash.indexOf(dataReassigned.event + "cbt") === -1)) {
                dataLayer.push(dataReassigned);
            }
        }
    }

    /**
     * check data type and parse string into JSON
     * @param {Object} data - data to push
     */
    static dataPush(data) {
        var dataReassigned = data;
        if ((typeof (dataReassigned) === "string") && (dataReassigned !== "")) {
            if ((dataReassigned != null) || (dataReassigned !== "null")) {
                dataReassigned = CBTController.parseJSON(unescape(dataReassigned));
                CBTController.hashPush(dataReassigned);
            }
        } else {
            CBTController.hashPush(dataReassigned);
        }
    }

    // Push data which specifies customer's number if they're logged in, otherwise specify "Anonymous"
    static clientNumberRun() {
        var dataLayerObj = {
            event: "page_view"
        };
        if (window.customerNumber) {
            dataLayerObj.user_id = customerNumber;
            dataLayerObj.user_type = "Registered";
        } else {
            delete dataLayerObj.user_id;
            dataLayerObj.user_type = "Guest";
        }
        var siteLanguage = window.CQuotient.locale;
        dataLayerObj.site_language = siteLanguage;
        CBTController.dataPush(dataLayerObj);
    }
}

document.addEventListener("DOMContentLoaded", function () {
    CBTController.clientNumberRun();
});
